
import Http from './Http'

export default {
    async getMatches() {
        return await Http.get(`/football/matches`)
    },
    async createMatchPreviewText(match_id, data) {
        return await Http.post(`/football/match/${match_id}/previewText`, data)
    },

}


